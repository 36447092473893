import React from 'react';
import './App.css';

const App: React.FC = () => {
  const currentPage = window.location.href;
  const search = window.location.search;
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://s3.amazonaws.com/colorcloud.io/images/spectro/spectro.png" className="App-logo" alt="logo" />
        <a className="open-link" href={`https://variable.app.link/W9faM6pa6Y${search}`}>
          <div>CONTINUE</div>
        </a>
      </header>
    </div>
  );
};

export default App;
